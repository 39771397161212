import React from 'react';

import Pagination from './Pagination';
import PeopleBlock from './PeopleBlock';
import ParamsList from './ParamsList';

const PeopleList = (props) => {

	return (

		<div className="people-list">

			{props.people.map((p, i) => {
				return (

					<PeopleBlock person={p} key={p.id} />

				)
			})}

			{props.count > props.perPage &&
				<Pagination model="people" count={props.count} current={parseInt(props.current)} perPage={props.perPage} params={props.params} />
			}
		</div>

	)
}

export default PeopleList;