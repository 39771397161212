import React from 'react';

import PageWrap from './components/PageWrap';
import TheatresList from './components/TheatresList';

class Theatres extends React.Component {

	constructor(props) {
	  super(props);
	
	  this.state = {
	  	theatres: [],
	  };
	}

	componentDidMount() {
		this.getTheatres();
	}

	fetch(ep) {
		return window.fetch(ep)
			.then(resp => resp.json())
			.catch(err => console.log(err));
	}

	getTheatres = () => {
		this.fetch('/api/theatres')
			.then(theatres => {
				this.setState({
					theatres: theatres
				});
			});
	}
	
	render() {
		let { theatres } = this.state;

		return (
			<PageWrap>
				{theatres[0] && <TheatresList theatres={theatres} />}
			</PageWrap>
		)
	}
}

export default Theatres;