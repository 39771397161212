import React from 'react';
import { NavLink } from 'react-router-dom'

import Search from './Search';

const NavMenu = (props) => {

	return (

		<nav className={"nav-menu" + ((props.navActive) ? " is-active" : "")}>
			<Search search={props.search} searchChange={props.searchChange} />
			<ul className="nav-menu__list">
				<li className="nav-menu__item">
					<NavLink to="/productions">Productions</NavLink>
				</li>
				<li className="nav-menu__item">
					<NavLink to="/people">People</NavLink>
				</li>
			</ul>
		</nav>

	)
}

export default NavMenu;