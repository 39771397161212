import React from 'react';
import { Link } from 'react-router-dom';

import Pagination from './Pagination';
import ParamsList from './ParamsList';

const ProductionsList = (props) => {

	let { productions } = props;

	const outputSrc = (p) => {
		let src;
		if(p.thumbnail){
			src = p.thumbnail.url
		} else if(p.photo) {
			src = p.photo.thumbnail
		} else {
			src = false;
		}

		return src;
	}

	return (

		<div className="productions-list">

			{productions.map((p, i) => {
				return (

					<Link to={"/productions/" + p.id} className="list-block" key={p.id}>
						<header className="list-block__header">
							<div className="list-block__image-wrap">
								{(outputSrc(p))
									?
										<img src={outputSrc(p)} className="list-block__image" alt="" />
									:
										<span className="list-block__image-letter">{p.title[0]}</span>
								}
							</div>
							<div className="list-block__title-wrap">
								<h3 className="list-block__title">{p.title}</h3>
								<span className="list-block__season">{p.season.name}</span>
							</div>
						</header>

						{p.typetags !== undefined &&
							<div className="list-block__typetags">
								<ul className="list-block__typetags-list list-block__list">
									{p.typetags.map((t, i) => {
										return (

											<li key={i}>{t.name}</li>

										)
									})}
								</ul>
							</div>
						}
						
						{p.seriestags !== undefined &&
							<div className="list-block__seriestags">
								<ul className="list-block__seriestags-list list-block__list">
									{p.seriestags.map((s, i) => {
										return (

											<li key={i}>{s.name}</li>

										)
									})}
								</ul>
							</div>
						}

					</Link>

				)
			})}

			{((props.count > props.perPage) && (props.count > 0) && (props.perPage > 0)) &&
				<Pagination count={props.count} model="productions" current={parseInt(props.current)} perPage={props.perPage} params={props.params} />
			}
		</div>
	)
}

export default ProductionsList;