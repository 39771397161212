import React, { useEffect, useState} from 'react';

import { fetchAPI } from '../helpers';

import SelectDD from './SelectDD';

const Filter = (props) => {

	const [ filter, setFilter ] = useState([]);

	useEffect(() => {
		fetchAPI('/api/' + props.query)
			.then(resp => {
				setFilter(resp.entries);
			})
	}, [props.query]);

	return (

		<div className="filter">
			{filter[0] &&

				<SelectDD params={props.params} query={props.query} placeholder={props.label} options={filter} updateContent={props.updateContent} />
				
			}
		</div>

	)
}

export default Filter;