import React from 'react';
import { Link } from 'react-router-dom';

import DetailBlock from './DetailBlock';

import { formatDate } from '../helpers';

const ProductionDetails = (props) => {

	let { production } = props;

	console.log(production);

	return (

		<div className="production__details">

			{production.season !== undefined &&
				<DetailBlock headline="Season" content={<Link to={"/productions?seasons=" + production.season.id}>{production.season.name}</Link>} />
			}

			{((production.date_preview !== null) && (production.date_preview !== undefined)) &&
				<DetailBlock headline="Preview Date" content={formatDate(production.date_preview)} />
			}

			{((production.date_opening !== null) && (production.date_opening !== undefined)) &&
				<DetailBlock headline="Opening Date" content={formatDate(production.date_opening)} />
			}

			{((production.date_closing !== null) && (production.date_closing !== undefined)) &&
				<DetailBlock headline="Closing Date" content={formatDate(production.date_closing)} />
			}

			{((production.performances !== null) && (production.performances !== undefined)) &&
				<DetailBlock headline="Performances" content={production.performances} />
			}

			{((production.previews !== null) && (production.previews !== undefined)) &&
				<DetailBlock headline="Previews" content={production.previews} />
			}

			{((production.theatre_configuration !== '') && (production.theatre_configuration !== undefined)) &&
				<DetailBlock headline="Theatre Configuration" content={<Link to={"/productions?configurations=" + production.theatre_configuration }>{production.theatre_configuration}</Link>} />
			}

			{((production.venue !== null) && (production.venue !== undefined)) &&
				<DetailBlock headline="Venue" content={<Link to={"/productions?venues=" + production.venue.id}>{production.venue.name}</Link>} />
			}
		</div>

	)
}

export default ProductionDetails;