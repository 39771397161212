import React from 'react';

const ProductionsNone = (props) => {

	return (

		<div className="productions-none">
			{(props.isLoading === true)
				?
					<h2 className="header-secondary">Loading Productions...</h2>
				:
					<h2 className="header-secondary">No Productions Found.</h2>
			}
		</div>

	)
}

export default ProductionsNone;