import React, { useState, useEffect } from 'react';

import { fetchAPI, isEmpty } from './helpers';

import PageWrap from './components/PageWrap';

import ProductionHeader from './components/ProductionHeader';
import ProductionDetails from './components/ProductionDetails';
import ProductionTabs from './components/ProductionTabs';
import ProductionAside from './components/ProductionAside';
import ProductionModal from './components/ProductionModal';

const Production = (props) => {
	const [ production, setProduction ] = useState({});
	const [ modalVisible, setModalVisible ] = useState(false);
	const [ galleryCurrent, setGalleryCurrent ] = useState(0);

	useEffect(() => {
		fetchAPI('/api/productions/' + props.match.params.id).then(resp => {
			setProduction(resp.production);
		});
	}, [props.match.params.id]);

	const toggleGallery = (i = 0) => {
		let current = modalVisible;
		setModalVisible(!current);
		setGalleryCurrent(i);
	}

	return (

		<PageWrap layout={props.layout} asideContent={<ProductionAside production={production} toggleGallery={toggleGallery} />}>
			{!isEmpty(production)
				?
					<div className="production">
						<ProductionHeader production={production} />
						<ProductionDetails production={production} />
						{(production.casts || production.staffs || production.songs || production.subsequents) &&
							<ProductionTabs production={production} />
						}
						{((production.galleries || production.photo) && modalVisible === true) &&
							<ProductionModal production={production} toggleGallery={toggleGallery} current={galleryCurrent} />
						}
					</div>
				:
					<div className="page-content">
						<h2 className="header-secondary">Loading...</h2>
					</div>
			}
		</PageWrap>

	)
}

export default Production;