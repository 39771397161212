import React from 'react';

const PeopleNone = (props) => {
	return (

		<div className="people-none">
			{(props.isLoading === true)
				?

					<h2 className="header-secondary">Loading People...</h2>
				:
					<h2 className="header-secondary">No People Found.</h2>
			}
		</div>

	)
}

export default PeopleNone;