import React from 'react';

import ParamsList from './ParamsList';

const ListHeader = (props) => {

	return (

		<header className="list-header">
			<h2 className="list-header__title">{props.title}</h2>
			<div className="list-header__filters">
				{props.children}
			</div>
			<ParamsList params={props.params} updateContent={props.updateContent} />
		</header>

	)
}

export default ListHeader;