import React, { useEffect, useState} from 'react';

import { fetchAPI } from './helpers';

import PageWrap from './components/PageWrap';
import ListHeader from './components/ListHeader';
import Filter from './components/Filter';
import PeopleList from './components/PeopleList';
import PeopleNone from './components/PeopleNone';

const People = (props) => {

	const [ people, setPeople ] = useState([]);

	const [ count, setCount ] = useState(0);
	const [ current, setCurrent ] = useState(0);
	const [ perPage, setPerPage ] = useState(0);
	const [ isLoading, setIsLoading ] = useState(true);

	const [ params, setParams ] = useState([]);
	const [ metaParams, setMetaParams ] = useState([]);

	useEffect(() => {
		updateParams();

		return () => {
			setParams([]);
		}
	}, []);

	useEffect(() => {
		if(params[0]) {
			fetchAPI(buildEndpoint())
				.then(resp => {
					setPeople(resp.entries);
					setCount(resp.meta.count);
					setCurrent(resp.meta.current);
					setPerPage(resp.meta.per_page);
					setMetaParams(resp.meta.params);
					setIsLoading(false);
				});
		}

		return () => {
			setPeople([]);
		}
	}, [params]);

	const updateParams = () => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);

		let paramsDefault = [ 'page', 'classifications' ],
			paramsUpdate = [];

		paramsDefault.forEach((p, i) => {
			let valueDefault = (p == 'page') ? 1 : false;
			paramsUpdate.push({
				param: p,
				value: (urlParams.get(p) == null) ? valueDefault : urlParams.get(p),
			});
		});

		setParams(paramsUpdate);
	}

	const buildEndpoint = () => {
		let ep = '/api/people';
		if(params !== undefined && params[0]) {
			params.forEach(function(p, i) {
				if(p.value !== '' && p.value) {
					let pref = (i == 0) ? '?' : '&';
					ep += (pref + p.param + '=' + p.value);	
				}
			});
		}

		console.log(ep);

		return ep;
	}

	const updateContent = (query, page) => {
		setIsLoading(true);
		setPeople([]);

		if(query !== undefined && query[0]) {
			let q = query[0],
				results = params.filter(p => p.param !== q.param),
				param = {
					param: q.param,
					value: q.value,
					label: q.label,
				};

			results.push(param);
			if(page !== undefined) {
				let pageIndex = results.findIndex(r => r.param == 'page');
				results[pageIndex].value = page;
			}
			
			setParams(results);
		}
	}

	return (
		<PageWrap layout={props.layout}>
			<ListHeader title="People" params={metaParams} updateContent={updateContent}>
				<Filter label="Classifications" query="classifications" updateContent={updateContent} />
			</ListHeader>
			{people[0]
				? <PeopleList params={metaParams} people={people} count={count} current={current} perPage={perPage} params={params} />
				: <PeopleNone isLoading={isLoading} />
			}
		</PageWrap>
	)
}

export default People;