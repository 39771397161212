import React, { useState, useEffect } from 'react';

import { fullName, fetchAPI, isEmpty, isCurrentRange } from './helpers';

import PageWrap from './components/PageWrap';
import PersonTabs from './components/PersonTabs';
import PersonAside from './components/PersonAside';

const Person = (props) => {
	const [ person, setPerson ] = useState({});

	useEffect(() => {
		fetchAPI('/api/people/' + props.match.params.id)
			.then(resp => {
				setPerson(resp.person);
			})
	}, [props]);

	return (
		
		<PageWrap layout={props.layout} asideContent={!isEmpty(person) && <PersonAside person={person} />}>
			<div className="main-content__block">
				{!isEmpty(person)
					?
						<div className="person">
							<header className="person__header">
								<h1 className="person__name">{fullName(person)}</h1>
								{isCurrentRange(person.resident_emeritus) &&
									<span className="person__secondary">Resident Acting Company Emeritus</span>
								}
								{isCurrentRange(person.resident_companies) &&
									<span className="person__secondary">Resident Acting Company</span>
								}
							</header>
							{(
								person.casts !== undefined ||
								person.staffs !== undefined ||
								person.resident_emeritus !== undefined ||
								person.resident_companies !== undefined
							) &&
								<PersonTabs person={person} />
							}
						</div>
					:
						<h2 className="header-secondary">Loading...</h2>
				}
			</div>
		</PageWrap>

	)
}

export default Person;