import React from 'react';

class Credit extends React.Component {

	render() {
		
		return (

			<div className="credit">
				<h2 className="credit__text">
					<a href="https://www.alleytheatre.org/" target="_blank" rel="noopener noreferrer" className="credit__link">
						<span className="credit__text-name">AlleyTheatre</span>.Org
					</a>
				</h2>
			</div>

		)
	}
}

export default Credit;