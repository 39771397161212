import React, { useState } from 'react';

import Sticky from "react-stickynode";

import Header from './Header';
import Credit from './Credit';

const PageWrap = (props) => {
	const [ search, setSearch ] = useState('');
	const [ navActive, setNavActive ] = useState(false);

	React.useEffect(() => {
		if(props.search !== undefined) {
			setSearch(props.search);
		}
	}, []);

	return (

		<div className={"page-wrap " + props.layout}>
			<Header search={search} setSearch={setSearch} navActive={navActive} setNavActive={setNavActive}>
				{props.headerContent}
			</Header>
			<aside className="sidebar">
				<Sticky top="#header">
					<div className="sidebar__content sidebar__content--desktop">
						{props.asideContent}
						<Credit />
					</div>
				</Sticky>
				<div className="sidebar__content sidebar__content--mobile">
					{props.asideContent}
				</div>
			</aside>
			<div id="main" className="main-content">
				<div className="main-content__block">
					{props.children}
				</div>
			</div>
		</div>

	)
}

export default PageWrap;