import React from 'react';
import { Link } from 'react-router-dom';

import { formatDate, fullName } from '../helpers';
import TableCast from './TableCast';
import TableSongs from './TableSongs';
import TableStaff from './TableStaff';

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

const ProductionTabs = (props) => {

	let { production } = props;

	return (

		<Tabs>
			<TabList>
				{production.casts[0] &&
					<Tab>Cast</Tab>
				}

				{production.staffs[0] &&
					<Tab>Production</Tab>
				}

				{production.songs[0] &&
					<Tab>Song Lists</Tab>
				}

				{production.subsequents[0] &&
					<Tab>Post Alley</Tab>
				}
			</TabList>

			{production.casts[0] &&
				<TabPanel>
					<TableCast cast={production.casts} />
				</TabPanel>
			}

			{production.staffs[0] &&
				<TabPanel>
					<TableStaff staffs={production.staffs} />
				</TabPanel>
			}

			{production.songs[0] &&
				<TabPanel>
					<TableSongs songs={production.songs} />
				</TabPanel>
			}

			{production.subsequents[0] &&
				<TabPanel>
					<table>
						<thead>
							<tr>
								<th>Theatre</th>
								<th>Start Date</th>
								<th>End Date</th>
							</tr>
						</thead>
						<tbody>
							{production.subsequents.map((s, i) => {
								return (

									<tr key={i}>
										<td><Link to={"/theatres/" + s.theatre.id}>{s.theatre.name}</Link></td>
										<td>{formatDate(s.date_start)}</td>
										<td>{formatDate(s.date_end)}</td>
									</tr>

								)
							})}
						</tbody>
					</table>
				</TabPanel>
			}
		</Tabs>

	)
}

export default ProductionTabs;