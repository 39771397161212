import React, { useState, useEffect} from 'react';

import { fetchAPI } from './helpers';

import PageWrap from './components/PageWrap';
import ListHeader from './components/ListHeader';
import Filter from './components/Filter';
import ProductionsList from './components/ProductionsList';
import ProductionsNone from './components/ProductionsNone';

const Productions = (props) => {

	const [ productions, setProductions ] = useState([]);
	
	const [ count, setCount ] = useState(0);
	const [ current, setCurrent ] = useState(0);
	const [ perPage, setPerPage ] = useState(0);
	const [ isLoading, setIsLoading ] = useState(true);

	const [ params, setParams ] = useState([]);
	const [ metaParams, setMetaParams ] = useState([]);

	useEffect(() => {
		updateParams();

		return () => {
			setParams([]);
		}
	}, []);

	useEffect(() => {
		if(params[0]) {
			fetchAPI(buildEndpoint())
				.then(resp => {
					setProductions(resp.entries);
					setCount(resp.meta.count);
					setCurrent(resp.meta.current);
					setPerPage(resp.meta.per_page);
					setMetaParams(resp.meta.params);
					setIsLoading(false);
				});
		}

		return () => {
			setProductions([]);
		}
	}, [params]);

	const updateParams = () => {
		const queryString = window.location.search;
		const urlParams = new URLSearchParams(queryString);

		let paramsDefault = [ 'page', 'seasons', 'seriestags', 'typetags', 'premiere', 'venues', 'configurations' ],
			paramsUpdate = [];

		paramsDefault.forEach((p, i) => {
			let valueDefault = (p == 'page') ? 1 : false;
			paramsUpdate.push({
				param: p,
				value: (urlParams.get(p) == null) ? valueDefault : urlParams.get(p)
			});
		});

		setParams(paramsUpdate);
	}

	const buildEndpoint = () => {
		let ep = '/api/productions';
		if(params !== undefined && params[0]) {
			params.forEach(function(p, i) {
				if(p.value) {
					let pref = (i == 0) ? '?' : '&';
					ep += (pref + p.param + '=' + p.value);	
				}
			});
		}

		return ep;
	}

	const updateContent = (query, page) => {
		setIsLoading(true);
		setProductions([]);

		if(query !== undefined && query[0]) {
			let q = query[0],
				results = params.filter(p => p.param !== q.param),
				param = {
					param: q.param,
					value: q.value,
					label: q.label,
				};

			results.push(param);
			if(page !== undefined) {
				let pageIndex = results.findIndex(r => r.param == 'page');
				results[pageIndex].value = page;
			}
			
			setParams(results);
		}
	}

	return (

		<PageWrap layout={props.layout}>
			<ListHeader title="Productions" params={metaParams} updateContent={updateContent}>
				<Filter label="Season" query="seasons" updateContent={updateContent} params={params} />
				<Filter label="Series" query="seriestags" updateContent={updateContent} params={params} />
			</ListHeader>
			{(productions[0])
				? <ProductionsList params={metaParams} productions={productions} count={count} current={current} perPage={perPage} />
				: <ProductionsNone isLoading={isLoading} />
			}
		</PageWrap>

	)
}

export default Productions;