import React from 'react';
import Select from 'react-select';

const SelectDD = (props) => {
	const [ options, setOptions ] = React.useState([]);
	const [ current, setCurrent ] = React.useState(false);

	React.useEffect(() => {
		buildOptions(props.options);
	}, [])

	React.useEffect(() => {
		if(options[0] && props.params !== undefined) {
			let optionParam = props.params.find(p => p.param == props.query);
			let optionIndex = options.findIndex(o => o.value == optionParam.value);

			setCurrent((optionIndex) ? options[optionIndex] : false);
		}
	}, [options]);

	const handleChange = (e) => {
		let queryObj = {
			param: props.query,
			value: e.value,
			label: e.label,
		}
		props.updateContent([queryObj], 1);
	}

	const styleProxy = new Proxy({}, {
		get: (target, propKey) => () => {}
	});

	const buildOptions = (f) => {
		let optionsList = [{ value: '', label: 'Show All', }];
		f.forEach(function(item, index) {
			optionsList.push({ value: item.id, label: item.name });
		});

		setOptions(optionsList);
	}

	return (

		<Select
			defaultValue={current}
			placeholder={props.placeholder}
			onChange={handleChange}
			options={options}
			styles={styleProxy}
			isSearchable={false}
			className="react-select-container"
			classNamePrefix="react-select"
		/>

	)
}

export default SelectDD;