import React from 'react';

import { isEmpty } from '../helpers';

const ProductionAside = (props) => {

	console.log('props.production: ');
	console.log(props.production);

	const photo = props.photo;
	const prod = props.production;

	const galleryCount = (i) => {
		return (props.production.photo) ?  i + 1 : i; 
	}

	return (

		<div className="production-sidebar">

			{photo &&
				<div className="sidebar__image-wrap">
					<img src={photo} className="sidebar__image" alt="" />
				</div>
			}
			
			{prod &&
				<div>
					{!isEmpty(props.production)
						?
							<div>
								<div className="sidebar__image-wrap">
									{(props.production.photo)
										?
											<button className="sidebar__image-link" onClick={() => { props.toggleGallery(0) }}>
												<img src={props.production.photo.featured} className="sidebar__image" alt={props.production.title} />
											</button>
										:
											<img src="https://alleydb.s3.us-east-2.amazonaws.com/default/production_placeholder.jpg" className="person-header__image sidebar__image" alt="default headshot" />
									}
								</div>
								{props.production.galleries &&
									<div className="sidebar__gallery">
										{props.production.galleries.map((g, i) => {
											if(i <= 3) {
												return (

													<button onClick={() => { props.toggleGallery(galleryCount(i)) }} className="sidebar__gallery-link" key={g.id}>
														<span className="sidebar__gallery-image-wrap">
															<img src={g.photo.gallery} className="sidebar__gallery-image" alt={g.caption} />
														</span>
													</button>

												)
											}
										})}
										<button className="button button--full" onClick={props.toggleGallery}>View Production Gallery</button>
									</div>
								}
							</div>
						:
							<div className="sidebar-content">
								<h2 className="header-secondary">Loading...</h2>
							</div>
					}
				</div>
			}
		</div>

	)
}

export default ProductionAside;