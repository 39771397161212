import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';

import Select from 'react-select';

import iconSearch from '../img/icon-search.svg';

const styleProxy = new Proxy({}, {
	get: (target, propKey) => () => {}
});

const options = [
	{
		value: "all",
		label: "All",
	},
	{
		value: "productions",
		label: "Productions",
	},
	{
		value: "people",
		label: "People",
	},
]

const SearchMain = (props) => {
	const [ search, setSearch ] = useState('');
	const [ active, setActive ] = useState('all');


	const handleSubmit = (e) => {
		e.preventDefault();
		if(search !== '') {
			let query = '/search/';
			query += (active !== 'all') ? active + '/' : '';
			props.history.push(query + search);
		}
	}

	const handleChange = (e) => {
		setSearch(e.target.value);
	}

	const selectChange = (e) => {
		setActive(e.value);
	}

	return (

		<form className="search-form" onSubmit={(e) => { handleSubmit(e) }} >
			<h2 className="search-form__title">Search the Database</h2>
			<div className="search-form__fields">
				<Select onChange={(e) => { selectChange(e); }} placeholder={props.placeholder} options={options} defaultValue={options[0]} styles={styleProxy} isSearchable={false} className="react-select-container" classNamePrefix="react-select" />
				<div className="search-form__input-wrap">
					<input type="text" className="search-form__input" value={search} onChange={(e) => { handleChange(e) }} />
					<button className="search-form__submit">
						<img src={iconSearch} alt="" />
					</button>
				</div>
			</div>
		</form>

	)
}

export default withRouter(SearchMain);