import React, { useState, useEffect } from 'react';
import Slider from "react-slick";

export default class ProductionModal extends React.Component {
	// const [ nav1, setNav1 ] = useState(null);
	// const [ nav2, setNav2 ] = useState(null);

	// useEffect(() => {
	// 	setNav1(slider1);
	// 	setNav2(slider2);
	// });

  constructor(props) {
    super(props);
    this.state = {
      nav1: null,
      nav2: null
    };
  }

  componentDidMount() {
    this.setState({
		nav1: this.slider1,
		nav2: this.slider2
    }, () => {
    	this.slider1.slickGoTo(this.props.current);
    	if(this.state.nav2 !== undefined) {
		    this.state.nav2.innerSlider.list.setAttribute('tabindex', 0);
			this.state.nav2.innerSlider.list.focus();
    	}
    });
  }

	render() {
		let { production } = this.props;

		return (

			<div className="modal modal--gallery">
				{(production.photo || production.galleries[0]) &&
					<div className="slider-wrap">
						<div className="featured-slider-wrap">
							<Slider
								className="featured-slider"
								asNavFor={this.state.nav2}
								ref={slider => (this.slider1 = slider)}
								arrows={false}
							>

								{production.photo &&

									<div className="featured-slider__block">
										<div className="featured-slider__content">
											<button onClick={this.props.toggleGallery} className="modal__close"><span className="icon--close"></span></button>
											<img src={production.photo.featured} alt="" />
											<div className="featured-slider__details">
												<span className="featured-slider__detail-counter">1 / {production.galleries.length + 1}</span>
											</div>
										</div>
									</div>

								}

								{production.galleries.map((g, i) => {
									return (

										<div className="featured-slider__block">
											<div className="featured-slider__content">
												<button onClick={this.props.toggleGallery} className="modal__close"><span className="icon--close"></span></button>
												<img src={g.photo.full} alt="" key={g.id} />
												<div className="featured-slider__details">
													<span className="featured-slider__detail-counter">{i + 2} / {production.galleries.length + 1}</span>
													{g.caption !== '' &&
														<span className="featured-slider__caption">{g.caption}</span>
													}
												</div>
											</div>
										</div>

									)
								})}
							</Slider>
						</div>

						{production.galleries[0] &&
							<div className="control-slider-wrap">
								<Slider
									className="control-slider"
									asNavFor={this.state.nav1}
									ref={slider => (this.slider2 = slider)}
									slidesToShow={4}
									swipeToSlide={true}
									focusOnSelect={true}
								>

									{production.photo &&
										<div className="control-slider__block">
											<img src={production.photo.featured} alt="" />
										</div>
									}

									{production.galleries.map((g, i) => {
										return (

											<div className="control-slider__block">
												<img src={g.photo.control} alt="" key={g.id} />
											</div>

										)
									})}
								</Slider>
							</div>
						}
					</div>
				}
			</div>

		)
	}
}