import React from 'react'

import PageWrap from './components/PageWrap';

class NotFound extends React.Component {
  
  render () {

    return (

    	<PageWrap layout="page-404">
    		<div className="page-content page-content--404">
	    		<h1>404</h1>
	    		<h2 className="header-secondary">Page Not Found</h2>
    		</div>
    	</PageWrap>
    )
  }
}

export default NotFound;