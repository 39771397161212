import React from 'react';
import { Link } from 'react-router-dom'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';

import { formatDate, mergeDataPerson } from '../helpers';

const PersonTabs = (props) => {
	const person = props.person;
	const items = mergeDataPerson(person);

	return (

		<Tabs>
			<TabList>
				{(items[0] && items !== undefined) &&
					<Tab>Credits</Tab>
				}
				{(person.resident_emeritus[0] || person.resident_companies[0]) &&
					<Tab>Company</Tab>
				}
			</TabList>

			{(items[0] && items !== undefined) &&
				<TabPanel>
					<table>
						<thead>
							<tr>
								<th>Production</th>
								<th>Role</th>
								<th>Season</th>
							</tr>
						</thead>
						<tbody>
							{items[0] && items !== undefined && items.map((s, i) => {
								return (

									<tr key={s.id}>
										{s.production &&
											<React.Fragment>
												<td className="person-table__production">
													{(s.production)
														?
														<Link to={"/productions/" + s.production.id}>{s.production.title}</Link>
														:
														<span>Production Not Listed.</span>
													}
												</td>
												<td>{s.position.name}</td>
												<td>
													{(s.production)
														?
														<Link to={"/productions?seasons=" + s.production.season.id}>
															{s.production.season.name}
														</Link>
														:
														<span>Production Not Listed.</span>
													}
												</td>
											</React.Fragment>
										}
									</tr>

								)
							})}
						</tbody>
					</table>
				</TabPanel>
			}

			{(person.resident_emeritus[0] || person.resident_companies[0]) &&
				<TabPanel>
					<table>
						<tbody>

							{person.resident_emeritus.map((re, i) => {
								return (

									<tr key={i}>
										<td>Resident Acting Company Emeritus</td>
										<td>{formatDate(re.date_start)} – {(formatDate(re.date_end) !== '') ? formatDate(re.date_end) : 'present'}</td>
									</tr>

								)
							})}

							{person.resident_companies.map((rc, i) => {
								return (

									<tr key={i}>
										<td>Resident Acting Company</td>
										<td>{formatDate(rc.date_start)} – {(formatDate(rc.date_end) !== '') ? formatDate(rc.date_end) : 'present'}</td>
									</tr>

								)
							})}

						</tbody>
					</table>
				</TabPanel>
			}
		</Tabs>

	)
}

export default PersonTabs;