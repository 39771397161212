import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import { fetchAPI } from './helpers';

import PageWrap from './components/PageWrap';
import ProductionsList from './components/ProductionsList';
import PeopleList from './components/PeopleList';

const SearchResults = (props) => {
	const [ people, setPeople ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);

	useEffect(() => {
		setIsLoading(true);
		fetchAPI('/api/search/people/' + props.match.params.text).then(resp => {
			if(resp.people !== undefined) { setPeople(resp.people); }
			setIsLoading(false);
		});
	}, [props])

	return (

		<PageWrap layout="search-results" search={props.match.params.text}>
			{(!isLoading)
				?
					<div>
						{(people[0])
							?
								<div className="search-results__block">
									<h2 className="search-results__header header-secondary">People</h2>
									<PeopleList people={people} />
									<Link to="/people" className="link search-results__link">View All People &rarr;</Link>
								</div>
							:
								<div className="search-results__none">
									<h2 className="header-secondary">No People Found</h2>
								</div>
						}
					</div>
				:
					<div className="loading-content">
						<h2 className="loading-content__title">Loading People...</h2>
					</div>
			}
		</PageWrap>

	)
}

export default SearchResults;