import React, { useState, useEffect } from 'react';

import { fetchAPI, isEmpty } from './helpers';

import PageWrap from './components/PageWrap';
import SearchMain from './components/SearchMain';
import ProductionAside from './components/ProductionAside';

const Home = (props) => {
	const [ photo, setPhoto ] = useState(false);

	useEffect(() => {
		fetchAPI('/api/homeimages/').then(resp => {
			setPhoto(resp.homeimages[0].homeimages.photo);
		});
	}, []);

	return (

	  	<PageWrap asideContent={<ProductionAside photo={photo} />}>
			<SearchMain />
	  	</PageWrap>

	)
}

export default Home;
