import React from 'react';
import { withRouter } from 'react-router-dom';

import iconSearch from '../img/icon-search.svg';

const Search = (props) => {

	const handleChange = (e) => {
		props.searchChange(e);
	}

	const handleSubmit = (e) => {
		e.preventDefault();
		if(props.search !== '') {
			props.history.push('/search/' + props.search);
		}
	}

	return (

		<div className="search">
			<button className="search__toggle">
				search
			</button>
			<form className="search__form" onSubmit={(e) => handleSubmit(e)}>
				<img src={iconSearch} className="search__icon" alt="Search Icon" />
				<input type="text" className="search__input" value={props.search} placeholder="Search..." onChange={(e) => handleChange(e)} />
			</form>
		</div>

	)
}

export default withRouter(Search);