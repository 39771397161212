import React from 'react';
import { Link } from 'react-router-dom';

import { fullName } from '../helpers';

const TableCast = (props) => {
	return (

		<table>
			<thead>
				<tr>
					<th className="th-people">People</th>
					<th className="th-role">Role</th>
				</tr>
			</thead>
			<tbody>
				{props.cast.map((c, i) => {
					return (

						<tr key={i}>
							<td className="td-people">
								<div className="cast-person">
									{(c.person.photo !== '')
										?
											<img src={c.person.photo} alt="" className="cast-person__image" />
										:
											<span className="cast-person__letter">{c.person.name_first[0]}</span>
									}
									<Link to={"/people/" + c.person.id} className="cast-person__link">
										{fullName(c.person)}
									</Link>
								</div>
							</td>
							<td className="td-role">{c.position}</td>
						</tr>

					)
				})}
			</tbody>
		</table>

	)
}

export default TableCast;