import React from 'react';

import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';

import Home from './Home';
import Productions from './Productions';
import Production from './Production';
import People from './People';
import Person from './Person';
import Theatres from './Theatres';
import Theatre from './Theatre';
import NotFound from './NotFound';

import SearchResults from './SearchResults';
import SearchPeopleResults from './SearchPeopleResults';
import SearchProductionsResults from './SearchProductionsResults';

const App = (props) => {

  return (
    <Router>
      <Switch>
        <Route path='/' exact>
          <Home />
        </Route>
        <Route path='/productions' exact render={(props) => <Productions {...props} layout="production-archive" /> } />
        <Route path='/productions/:id' exact render={(props) => <Production {...props} layout="production-single" /> } />
        <Route path='/people' exact render={(props) => <People {...props} layout="person-archive" /> } />
        <Route path='/people/:id' exact render={(props) => <Person {...props} layout="person-single" /> } />
        <Route path='/theatres' exact component={Theatres} />
        <Route path='/theatres/:id' exact component={Theatre} />

        <Route path='/search/:text' exact render={(props) => <SearchResults {...props} layout="search" /> } />
        <Route path='/search/people/:text' exact component={SearchPeopleResults} />
        <Route path='/search/productions/:text' exact component={SearchProductionsResults} />

        <Route component={NotFound} />
      </Switch>
    </Router>
  )
}

export default App;