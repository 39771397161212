import React from 'react';
import { Link } from 'react-router-dom'

import NavMenu from './NavMenu';

const Header = (props) => {

	return (

		<header id="header" className="header" role="banner">
			<div className="logo-wrap">
				<h1 className="logo">
					<Link to="/" className="logo__link">
						<span className="c-red">Alley</span>Theatre
					</Link>
					<small className="logo__secondary">Database</small>
				</h1>
			</div>
			<button className={"nav-toggle" + ((props.navActive) ? " is-active" : "")} onClick={() => {props.setNavActive(!props.navActive)}}>
				<span className="nav-toggle__icon"></span>
			</button>
			<NavMenu search={props.search} searchChange={(e) => props.setSearch(e.target.value)} navActive={props.navActive} setNavActive={props.setNavActive} />
		</header>

	)
}

export default Header;