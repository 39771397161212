import React from 'react';

const TableSongs = (props) => {
	return (

		<table>
			<thead>
				<tr>
					<th>Song Title</th>
				</tr>
			</thead>
			<tbody>
				{props.songs.map((s, i) => {
					return (

						<tr key={i}>
							<td>{s.title}</td>
						</tr>
					
					)
				})}
			</tbody>
		</table>

	)
}

export default TableSongs;