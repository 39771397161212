import React from 'react';

const ParamsList = (props) => {

	const handleClick = (p) => {
		let query = {
			param: p.param,
			value: false,
			label: false,
		};
		props.updateContent([query], 1);
	}

	console.log(props.params);

	return (

		<div className="params-list-wrap">
			{props.params !== undefined &&
				<ul className="params-list">
					{props.params.map((p, i) => {
						return (
							<li className="params-list__item" key={i}>
								{(p.name !== '' && p.name !== undefined)
									?
										<span className="params-list__item-label">{p.name}</span>
									:
										<span>loading...</span>
								}
								<button className="params-list__item-close" onClick={() => handleClick(p)}></button>
							</li>
						)
					
					})}
				</ul>
			}
		</div>
	)
}

export default ParamsList;