import React from 'react';
import { Link } from 'react-router-dom';

import { fullName, isCurrentRange } from '../helpers';

const PeopleBlock = (props) => {
	const p = props.person;

	let isEmeritus = isCurrentRange(p.resident_emeritus),
		isCompanies = isCurrentRange(p.resident_companies);

	return (

		<Link className="list-block" to={"/people/" + p.id}>
			<header className="list-block__header">
				<div className="list-block__image-wrap">
					{(p.photo)
						?
							<img src={p.photo.thumbnail} className="list-block__image" alt="" />
						:
							<span className="list-block__image-letter">{(p.name_first !== undefined) ? p.name_first[0] : '' }</span>
					}
				</div>
				<div className="list-block__title-wrap">
					<h3 className="list-block__title">{fullName(p)}</h3>
					{(isEmeritus || isCompanies) &&
						<ul className="list-block__list">
							{isEmeritus &&
								<li>Resident Acting Company Emeritus</li>
							}
							{isCompanies &&
								<li>Resident Acting Company</li>
							}
						</ul>
					}
				</div>
			</header>
			
			{p.classifications !== undefined &&
				<div className="list-block__classifications">
					<ul className="list-block__classifications-list list-block__list">
						{p.classifications.map((c, i) => {
							return (

								<li key={i}>{c.name}</li>

							)
						})}
					</ul>
				</div>
			}

		</Link>

	)
}

export default PeopleBlock;