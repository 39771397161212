import React, { useState, useEffect } from 'react';

const outputURL = (model, page, params) => {
	let url = '/' + model + '?page=' + page;
	if(params !== undefined) {
		params.forEach((p, i) => {
			if(p.value !== false && p.param !== 'page') {
				url += ('&' + p.param + '=' + p.value);
			}
		});
	}

	return url;
}

const Pagination = (props) => {
	const [ totalPages, setTotalPages ] = useState(1);
	const [ pageMargin, setPageMarge ] = useState(1);
	const [ pageRange, setPageRange ] = useState(5);

	useEffect(() => {
		let pageCount = Math.ceil(props.count / props.perPage);
		if(isFinite(pageCount)) {
			setTotalPages(pageCount);
		}
	}, [props]);

	let lowEnd;

	// if current is less than Range
	if(props.current <= pageRange) {
		lowEnd = 1;
	} else if(props.current > totalPages - pageRange) {
		lowEnd = totalPages - pageRange + 1;
	} else {
		lowEnd = props.current;
	}


	// if current is top of Range
	// if((props.current < totalPages - pageMargin) && (props.current <= pageMargin + 1)) {
	// 	lowEnd = 1;
	// } else if(totalPages < pageRange && props.current < pageRange) {
	// 	lowEnd = 1;
	// } else if(props.current > (totalPages - pageRange)) {
	// 	lowEnd = totalPages - (pageRange - 1);


	// } else if(props.current > totalPages - (pageMargin + 1)) {
	// 	lowEnd = props.current;
	// } else if(props.current >= totalPages - pageMargin) {
	// 	lowEnd = parseInt(totalPages - (pageRange - 1));
	// } else if(props.current < totalPages - pageMargin) {
	// 	lowEnd = totalPages - (pageRange - 1);

	let range = (pageRange < totalPages) ? pageRange : totalPages;
	let highEnd = lowEnd + range - 1;
	let currentRange = [];
	for(var i = lowEnd; i <= highEnd; i++) {
		currentRange.push(parseInt(i));
	}

	// const buildRange = () => {

	// }

	return (

		<div className="pagination">
			<nav className="pagination__nav">
				<ul className="pagination__list">
					{ // Previous Arrow
					props.current !== 1 &&
						<li className="pagination__item pagination__item--arrow-prev">
							<a href={outputURL(props.model, props.current - 1, props.params)} className="pagination__link">&larr;</a>
						</li>
					}

					{ // Previous Pagination Margin Links
					(props.current > pageRange && totalPages > pageRange) &&
						Array.from(Array(pageMargin)).map((_, i) => {
							return (
								<PaginationItem model={props.model} current={props.current} v={ i + 1 } i={i} />
							)
						})
					}

					{ // Previous Margin Ellipsis
					(props.current > pageRange && totalPages > pageRange) &&
						<PaginationEllipsis />
					}

					{ // Primary Range
					currentRange.map((v, i) => {
						return (
							<PaginationItem model={props.model} current={props.current} params={props.params} v={v} i={i} />
						)
					})}

					{ // Next Margin Ellipsis
					(props.current < (totalPages - (pageRange - 1)) &&
						<PaginationEllipsis />
					)}

					{ // Next Pagination Margin Links
					props.current < (totalPages - (pageRange - 1)) &&

						Array.from(Array(pageMargin)).map((_, i) => {
							let num = totalPages - pageMargin + i + 1;
							return (
								<PaginationItem model={props.model} current={props.current} params={props.params} v={num} i={i} />
							)
						})
					}

					{ // Next Arrow
					props.current !== totalPages &&
						<li className="pagination__item pagination__item--arrow-next">
							<a href={outputURL(props.model, props.current + 1, props.params)} className="pagination__link">&rarr;</a>
						</li>
					}
				</ul>
			</nav>
		</div>

	)
}

const PaginationItem = (props) => {
	return (

		<li className="pagination__item" key={props.i}>
			<a href={outputURL(props.model, props.v, props.params)} className={"pagination__link" + ((props.current == (props.v)) ? " is-active" : "")}>{props.v}</a>
		</li>

	)
}

const PaginationEllipsis = () => {
	return <span className="pagination__ellipsis">&hellip;</span>
}

export default Pagination;