import React from 'react';

const DetailBlock = (props) => {

	return (

		<div className="detail-block">
			<h4 className="detail-block__headline">{props.headline}</h4>
			<span className="detail-block__content">
				{props.content}
			</span>
		</div>

	)
}

export default DetailBlock;