import React from 'react';
import { Link } from 'react-router-dom';

class TheatresList extends React.Component {

	render() {
		return (

			<div>
				{this.props.theatres.map((t, i) => {
					return (

						<h1 key={t.id}><Link to={"/theatres/" + t.id}>{t.name}</Link></h1>

					)
				})}
			</div>

		)
	}
}

export default TheatresList;