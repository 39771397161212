import React, { useState, useEffect} from 'react';
import { Link } from 'react-router-dom';

import { fetchAPI } from './helpers';

import PageWrap from './components/PageWrap';
import ProductionsList from './components/ProductionsList';
import PeopleList from './components/PeopleList';

const SearchProductionsResults = (props) => {
	const [ productions, setProductions ] = useState([]);
	const [ isLoading, setIsLoading ] = useState(true);

	useEffect(() => {
		setIsLoading(true);
		fetchAPI('/api/search/productions/' + props.match.params.text).then(resp => {
			if(resp.productions !== undefined) { setProductions(resp.productions); }
			setIsLoading(false);
		});
	}, [props])

	return (

		<PageWrap layout="search-results" search={props.match.params.text}>
			{(!isLoading)
				?
					<div>
						{(productions[0])
							?
								<div className="search-results__block">
									<h2 className="search-results__header header-secondary">Productions</h2>
									<ProductionsList productions={productions} />
									<Link to="/productions" className="link search-results__link">View All Productions &rarr;</Link>
								</div>
							:
								<div className="search-results__none">
									<h2 className="header-secondary">No Productions Found</h2>
								</div>
						}
					</div>
				:
					<div className="loading-content">
						<h2 className="header-secondary">Loading Productions...</h2>
					</div>
			}
		</PageWrap>

	)
}

export default SearchProductionsResults;