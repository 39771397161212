import React from 'react';
import { Link } from 'react-router-dom';

const ProductionHeader = (props) => {

	let prod = props.production;

	return (

		<header className="production__header">
			<h2 className="production__surtitle">{prod.surtitle}</h2>
			<h1 className="production__title">{prod.title}</h1>
			<h3 className="production__subtitle">{prod.subtitle}</h3>

			{(prod.seriestags || prod.typetags || prod.premiere !== '') &&
				<ul className="production__series">
					{prod.seriestags && prod.seriestags.map((s, i) => {
						return (

							<li key={s.id}>
								<Link to={"/productions?seriestags=" + s.id} className="pill">{s.name}</Link>
							</li>

						)
					})}

					{prod.seriestags && prod.typetags.map((t, i) => {
						return (

							<li key={t.id}>
								<Link to={"/productions?typetags=" + t.id} className="pill">{t.name}</Link>
							</li>

						)
					})}

					{prod.premiere !== '' &&
						<li>
							<Link to={"/productions?premiere=" + prod.premiere} className="pill">{prod.premiere} Premiere</Link>
						</li>
					}
				</ul>
			}
		</header>

	)
}

export default ProductionHeader;