import React from 'react';
import { Link } from 'react-router-dom';

import { fullName } from '../helpers';

const PersonHeader = (props) => {

	return (

		<div className="person-sidebar">
			<div className="sidebar__image-wrap">
				{(props.person.photo)
					?
						<img src={props.person.photo.featured} className="sidebar__image" alt={fullName(props.person) + " headshot"} />
					:
						<img src="https://alleydb.s3.us-east-2.amazonaws.com/default/Alley-ProfileImage.svg" className="sidebar__image" alt="default headshot" />
				}
			</div>
			{props.person.classifications !== undefined &&
				<ul className="sidebar__tag-list">
					{props.person.classifications.map((c, i) => {
						return (

							<li className="sidebar__tag-item" key={i}>
								<Link to={"/people?classifications=" + c.id} className="pill">{c.name}</Link>
							</li>

						)
					})}
				</ul>
			}
		</div>

	)
}

export default PersonHeader;