import React from 'react';
import { Link } from 'react-router-dom';

import PageWrap from './components/PageWrap';
// import TheatresList from './components/TheatresList';

class Theatre extends React.Component {

	constructor(props) {
	  super(props);
	
	  this.state = {
	  	theatre: {
	  		production: []
	  	}
	  };
	}

	componentDidMount() {
		this.getTheatre();
	}

	fetch(ep) {
		return window.fetch(ep)
			.then(resp => resp.json())
			.catch(err => console.log(err));
	}

	getTheatre = () => {
		this.fetch('/api/theatres/' + this.props.match.params.id)
			.then(theatre => {
				this.setState({
					theatre: theatre
				});
			});
	}
	
	render() {
		let { theatre } = this.state;

		return (
			<PageWrap>
				<h1>{theatre.name}</h1>
				{theatre.url &&
					<a href={theatre.url}>{theatre.url}</a>
				}
				{theatre.production &&
					<ul>
						{theatre.production.map((p, i) => {
							return (
								<li>
									<Link to={"/productions/" + p.id}>{p.title} - {p.season.name}, {p.theatre.name}</Link>
								</li>
							)
						})}
					</ul>
				}
			</PageWrap>
		)
	}
}

export default Theatre;