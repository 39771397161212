export const fetchAPI = (ep) => {
	return window.fetch(ep)
		.then(resp => resp.json())
		.catch(err => console.log(err));
}

export const formatDate = (date) => {
	let dateString = (date !== null)
		? new Date(date).toLocaleDateString(
			'en-us',
			{
				year: 'numeric',
				month: 'long',
				day: 'numeric',
			}
		)
		: ''

	return dateString;
}

export const fullName = (p) => {
	let f = (p.name_first) ? p.name_first : '';
	let m = (p.name_middle) ? p.name_middle : '';
	let l = (p.name_last) ? p.name_last : '';

	return (f + " " + m + " " + l)
}

export const isEmpty = (obj) => {
	if (obj) return false;

	for (var key in obj) {
		if (obj[key] !== null && obj[key] !== "") { return false; }
	}

	return true;
}

export const isCurrentRange = (list) => {
	if (isEmpty(list)) { return false; }
	return list.some((el) => el.date_end === null);
}

export const mergeDataPerson = (person) => {
	const modifiedCast = person.casts.map(item => {
		const name = { name: item.position };
		const position = name;
		item.position = position;
		return item;
	});

	const unifiedData = person.staffs.concat(modifiedCast);

	//clean data
	const cleanData = [];
	unifiedData.forEach(item => {
		if (item.production && item.production.season && item.production.season.name) {
			cleanData.push(item);
		}
	});

	const sortedData = cleanData.sort((itemA, itemB) => {
		const seasonStartA = parseInt(itemA.production.season.name.split('-')[0]);
		const seasonStartB = parseInt(itemB.production.season.name.split('-')[0]);

		if (seasonStartA < seasonStartB) {
			return 1;
		} else if (seasonStartA > seasonStartB) {
			return -1;
		}
		return 0;
	});

	return sortedData;
}